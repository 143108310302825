.scrollspy {
    position: sticky;
    top: 3rem;
    z-index: 100;
    transition: top .4s ease;

    &.active-sticky {
        > h2 {
            @include visually-hidden;
        }

        .collapse-toggle {
            @include media-breakpoint-up(md) {
                display: block;
            }

            &[aria-expanded='true'] {
                @include media-breakpoint-up(md) {
                    border-bottom-color: transparent;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .incaicon-angle-right {
                    @include media-breakpoint-up(md) {
                        transform: translateY(-50%) rotate(-270deg);
                    }
                }
            }
        }

        .collapse, .collapsing {
            @include media-breakpoint-up(md) {
                position: absolute;
                margin-top: -.3rem;
                width: 100%;
                padding: 0 1.6rem .9rem;
                background-color: $white;
                border-radius: 0 0 2.4rem .4rem;
                border: {
                    top: 0;
                    bottom: .2rem;
                    left: .2rem;
                    right: .2rem;
                    style: solid;
                    color: $ecume-360;
                }
            }
        }
    }

    &:not(.active-sticky) {
        @include media-breakpoint-up(md) {
            padding: 1.5rem 1.6rem .9rem;
            border-radius: .4rem .4rem 2.4rem;
            background-color: $white;
            border: .2rem solid $ecume-360;
        }

        .collapse {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    .collapse-toggle {
        display: none;
        padding: 1.6rem 8.5rem 1.6rem 1.6rem;
        border-radius: .4rem .4rem 2.4rem;
        background-color: $white;
        border: .2rem solid $ecume-360;
        text-align: left;
        width: 100%;
        position: relative;

        &__title {
            @include media-breakpoint-up(md) {
                font-size: 1.8rem;
                font-weight: 800;
            }
        }

        .incaicon-angle-right {
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 1.6rem;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
                width: 5.6rem;
                height: 5.6rem;
                border: .2rem solid $ecume-125;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2.4rem;
                transition: all .4s ease;
            }
        }
    }

    .collapse:not(.show) {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .collapsing {
        height: auto;
        transition: unset;
        overflow: unset;
    }

    > h2 {
        font-size: 1.8rem;
        margin-bottom: 1.8rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1.1rem;
        }
    }

    .dropdown-toggle {
        width: 100%;
        background-color: $white;
        border: .1rem solid $gray-500;
        border-radius: .2rem;
        padding: .8rem 3.4rem .8rem .8rem;
        text-align: left;
        white-space: unset;
        line-height: 1.2;
        position: relative;

        &::after {
            display: none;
        }

        .incaicon-angle-right {
            position: absolute;
            right: .8rem;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            border-radius: 50%;
            font-size: 2.4rem;
            transition: all .4s ease;
        }

        &[aria-expanded='true'] {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .incaicon-angle-right {
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }

    &__list {
        @include media-breakpoint-up(md) {
            position: unset;
            border: 0;
            padding: 0;
        }

        @include media-breakpoint-down(md) {
            padding: .8rem;
            border-bottom-left-radius: .2rem;
            border-bottom-right-radius: .2rem;
            margin-top: -.2rem!important;
            width: 100%;
            border: {
                top: 0;
                left: .1rem;
                right: .1rem;
                bottom: .1rem;
                style: solid;
                color: $gray-500;
            }
        }

        .dropdown-item {
            padding: .2rem 0 .6rem;
            white-space: unset;

            &:hover, &:focus {
                background: 0;
                text-decoration: underline;
            }

            &.active, &:active {
                background: none;
                color: inherit;
            }
        }
    }

    &-anchor {
        scroll-margin-top: 37rem;

        @include media-breakpoint-down(md) {
            scroll-margin-top: 20rem;
        }
    }
}
