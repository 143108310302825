@import '../../../../node_modules/vanillajs-datepicker/sass/datepicker';

.datepicker {
    span {
        border-radius: 50%;
    }

    &-view {
        width: 24rem;
    }

    &-grid {
        padding: 0 .8rem;
    }

    &-cell {
        min-width: 3.2rem;
        height: 3.2rem;

        &.focused {
            border: .1rem solid var(--ecume-360);
            color: var(--ecume-360);

            &:not(.selected) {
                background: var(--bs-white);
            }
        }

        &.selected {
            background: var(--ecume-125);
            color: var(--bs-white)!important;
            border-color: var(--ecume-125);

            &:hover {
                background: var(--ecume-125);
                color: var(--bs-white);
                border-color: var(--ecume-125);
            }
        }


    }

    .dow {
        font-size: 1.6rem;
        color: var(--ecume-360);
        font-weight: 400;
        text-transform: capitalize;
        height: 3.2rem;
    }

    .days-of-week {
        padding: 0 .8rem;
    }

    &-controls {
        .button {
            font-size: 1.6rem;
            font-weight: 400;

            &.view-switch {
                order: 1;
                justify-content: flex-start;
                text-transform: capitalize;
                padding-right: 2.4rem;
                background-position: center right;
                background-repeat: no-repeat;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI0IDI1IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNyAxMC44NTM1TDEyIDE1Ljg1MzVMMTcgMTAuODUzNUg3WiIgZmlsbD0iIzE3MjAzQiIvPgo8L3N2Zz4K');
            }
            &.prev-button {
                order: 2;
                transform: rotate(180deg);
            }
            &.next-button {
                order: 3;
            }
        }
    }

}
