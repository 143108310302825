@import '../../../../node_modules/select2/src/scss/core';
@import '../../../../node_modules/select2/src/scss/multiple';

$select2: 'select2';
.#{$select2} {
    &-container {
        border: .1rem solid $gray-400;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        .#{$select2}-selection {
            &--multiple {
                height: 4.2rem;
            }
        }

        &--open {
            .#{$select2}-dropdown--below {
                border-top: .2rem solid var(--ecume-360);
                border-top-left-radius: .2rem;
                border-top-right-radius: .2rem;
            }
        }

        &--focus, &:focus-visible {
            outline: .1rem auto Highlight;
            outline: -webkit-focus-ring-color auto .1rem;
        }

        + .#{$select2}-container {
            border: none;
        }
    }

    &-dropdown {
        border-radius: .4rem .4rem 2.4rem;
        border: .2rem solid var(--ecume-360);

        @include media-breakpoint-up(lg) {
            min-width: 35rem;
        }
    }

    &-results {
        &__options {
            padding: .8rem 0;

            > * {
                color: var(--ecume-125);
            }
        }

        &__option[role='option'] {
            padding: .8rem 1.6rem .8rem 4rem !important;
            position: relative;
            color: var(--ecume-125);
            margin-bottom: .1rem;

            &::before {
                width: 1.8rem;
                height: 1.8rem;
                position: absolute;
                left: .8rem;
                top: 1.4rem;
                border: .2rem solid var(--ecume-125);
                content: '';
                display: block;
            }

            &::after {
                width: 1.4rem;
                height: 1.4rem;
                position: absolute;
                left: 1rem;
                top: 1.6rem;
                border: .2rem solid var(--bs-white);
                background: var(--ecume-125);
                content: '';
                display: none;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &__option {
            &--highlighted[role='option'] {
                background-color: #5897fb;
                color: $white;
                text-shadow: .05rem 0 0 currentColor;
                outline: .1rem solid var(--ecume-125) !important;
            }

            &--selected[role='option'] {
                background-color: var(--ecume-975) !important;
                color: var(--ecume-125) !important;

                &::after {
                    display: block;
                }
            }
        }

        &__group {
            color: var(--ecume-125);
        }
    }

    &-container--default {
        .#{$select2} {
            &-selection--multiple {
                background-color: var(--bs-white);
                border: 0;
                border-radius: .2rem;
            }

            &-search--inline {
                display: none;

                .select2-search__field {
                    margin-top: 0;
                }
            }
        }
    }

    &-selection {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-position: right .75rem center;
        background-size: 1.6rem 1.2rem;

        > .select2-selection__rendered {
            display: none !important;
        }
    }

    .selected-text {
        position: absolute;
        top: .1rem;
        left: .1rem;
        color: var(--ecume-125) !important;
        background: $white;
        display: block;
        height: 3.9rem;
        padding: .8rem .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 3rem);

        > span {
            display: inline-block;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        * {
            color: inherit;
            white-space: nowrap;
        }
    }

    &-selection__choice {
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
}

//dropdown as select
.dropdown-select {
    * {
        color: var(--ecume-125);
    }

    &-btn {
        height: 4.2rem;
        position: relative;
        text-align: left;
        padding-right: 3rem;

        &::after {
            display: none;
        }

        .text {
            display: block;
            overflow: hidden;
        }
    }

    &-list {
        border-radius: .4rem .4rem 2.4rem;

        [role='checkbox'] {
            position: relative;
            padding: .8rem 1.6rem .8rem 4rem;

            &::before {
                width: 1.8rem;
                height: 1.8rem;
                position: absolute;
                left: .8rem;
                top: 1.4rem;
                border: .2rem solid var(--ecume-125);
                content: '';
                display: block;
            }

            &::after {
                width: 1.4rem;
                height: 1.4rem;
                position: absolute;
                left: 1rem;
                top: 1.6rem;
                border: 2px solid var(--bs-white);
                background: var(--ecume-125);
                content: '';
                display: none;
            }

            &[aria-checked='true'] {
                &::after {
                    display: block;
                }
            }
        }
    }

    &-text {
        padding: .8rem 1.6rem;
        font-size: 1.6rem;

        &:nth-child(odd) {
            background-color: var(--ecume-975);
        }

        a {
            text-decoration: none;
        }
    }
}
