@mixin layout-half-container {
    padding-left: 1.8rem;

    @include media-breakpoint-up(sm) {
        padding-left: calc((100vw - (54rem - $grid-gutter-width)) / 2);
    }

    @include media-breakpoint-up(md) {
        padding-left: calc((100vw - (72rem - $grid-gutter-width)) / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-left: calc((100vw - (96rem - $grid-gutter-width)) / 2);
    }

    @include media-breakpoint-up(xl) {
        padding-left: calc((100vw - (114rem - $grid-gutter-width)) / 2);
    }

    @include media-breakpoint-up(xxl) {
        padding-left: calc((100vw - (130.8rem - $grid-gutter-width)) / 2);
    }
}

@mixin placeholder {
    &::placeholder {
        @content;
    }
}
