.article {
    &-section {
        margin: 0 auto 13.6rem;
        max-width: 76.8rem;

        @include media-breakpoint-down(md) {
            margin: 0 auto 6.4rem;
        }

        &.small-section {
            max-width: 65.6rem;
        }

        > h2, > .like-h2 {
            @include media-breakpoint-down(md) {
                padding-right: 2.5rem;
            }
        }

        &-date {
            font-size: 1.4rem;
            margin: 3.2rem 0;

            @include media-breakpoint-down(md) {
                margin: .8rem 0 1.6rem;
            }
        }

        &-chapo {
            font-size: 1.8rem;
            line-height: 1.444;
            margin: 1.6rem 0 4.8rem;

            @include media-breakpoint-down(md) {
                margin: 1.6rem 0 3.2rem;
            }
        }

        .tags-group {
            display: flex;
            flex-wrap: wrap;
            gap: .8rem;
            line-height: normal;
            margin: 0;

            .tag {
                border-radius: .4rem .4rem .8rem 0;
                border: .1rem solid var(--ecume-125);
                font-size: 1.4rem;
                padding: .8rem;
                margin: 0;
            }
        }

        .fr-content-media, > .enhancedimage--wrapper {
            margin: 1.6rem 0 4rem;

            @include media-breakpoint-down(md) {
                margin: 1.6rem -1.6rem 3.2rem;
            }

            img {
                border-radius: .4rem .4rem 2.4rem;

                @include media-breakpoint-down(md) {
                    border-radius: 2.4rem .4rem;
                }
            }

            .fr-content-media__caption {
                @include media-breakpoint-down(md) {
                    padding: .8rem 1.6rem 0;
                }
            }
        }

        .date-project {
            > span {
                font-size: 1.8rem;
                font-weight: 800;
                line-height: 1.444;

                @include media-breakpoint-down(md) {
                    line-height: 1.333;
                }
            }

            time {
                @include media-breakpoint-down(md) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
