.form-control, .form-select, option {
    .block-bg-dark & {
        color: var(--ecume-125);
    }
}

.form-moteur {
    form {
        display: flex;
        justify-content: space-between;
        align-items: end;
        flex-wrap: wrap;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: .8rem;
        }

        label {
            font-weight: 400;
            font-size: 1.6rem;
            padding-right: .8rem;
            flex: 0;
        }

        .label-block {
            label {
                display: block;
            }
        }

        .select-100 {
            .select2-container {
                width: 100%!important;
            }
        }

        .long-label {
            label {
                flex: 1;
            }
        }

        input, select {
            height: 4.2rem;
        }

        select {
            width: 20.8rem;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }

        input {
            max-width: 24rem;
            width: auto;

            @include media-breakpoint-down(md) {
                max-width: 13rem;
            }

            &.form-control-long {
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    min-width: 43.2rem;
                }
            }
        }

        input::-webkit-calendar-picker-indicator {
            pointer-events: none!important;
        }

        input[type='date']::-webkit-input-placeholder {
            pointer-events: none!important;
        }

        .form-content {
            display: flex;
            align-items: end;
            flex: 0;

            @include media-breakpoint-down(lg) {
                display: block;
                width: 100%;

                .align-items-start {
                    flex-direction: column !important;
                }

                > .form-item {
                    width: 100%;

                    + * {
                        margin-top: 2.4rem;
                    }
                }
            }
        }

        .form-item {
            padding-right: 0;
            position: relative;

            @include media-breakpoint-up(lg) {
                padding-right: 1.6rem;
            }

            @include media-breakpoint-down(lg) {
                margin-top: 3rem;
                width: 100%;
            }

            .btn-link {
                color: $white;

                &:hover, &:focus {
                    color: $white;
                }
            }

            .form-select:disabled {
                opacity: .4
            }
        }

        fieldset.form-item {
            min-width: initial;
            padding: 0;

            @include media-breakpoint-up(lg) {
                padding-right: 1.6rem;
            }
        }

        .date-range {
            justify-content: space-between;

            .form-item {
                display: flex;
                align-items: center;
                width: calc(50% - .8rem);
                padding-right: 0;

                &:first-child {
                    margin-right: 1.6rem;
                }
            }
        }
    }

    .form-buttons {
        display: flex;
        align-items: end;
        gap: 2.4rem;

        @include media-breakpoint-down(lg) {
            width: 100%;
            align-items: center;

            .btn {
                width: 50%;
                justify-content: center;
            }
        }

        .btn-submit {
            .incaicon {
                color: var(--ecume-125);
                font-size: 1.4rem;
                margin-top: .2rem;
            }
        }

        .btn-reset {
            opacity: .4;

            &[data-enabled='true'] {
                opacity: 1;
            }

            .incaicon {
                font-size: 1.6rem;
                margin-top: .2rem;
                font-weight: 800;
                color: currentColor;
            }

            &:hover {
                opacity: 1;

                .incaicon {
                    color: var(--ecume-125);
                }
            }

            &:active {
                opacity: 1;
                border-color: var(--bs-btn-active-color);
            }

            &:focus {
                opacity: 1;
            }
        }
    }

    &-pro {
        form {
            margin: 0;

            .row {
                gap: 2.4rem 0;

                .form-item-input {
                    .form-control {
                        @include media-breakpoint-down(md) {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }

                .date-range {
                    .form-item {
                        width: 50%;
                    }
                }

                .form-content-multiple {
                    @include media-breakpoint-only(lg) {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                    }
                }

                .form-buttons {
                    justify-content: center;
                    margin: 0 !important;
                }
            }
        }
    }

    .autoComplete_wrapper {
        display: block;

        input {
            border: var(--bs-border-width) solid var(--bs-border-color) !important;
            background-image: none !important;
            color: var(--ecume-125) !important;
            padding: .375rem .75rem;
            border-radius: var(--bs-border-radius) !important;
            max-width: none !important;
            width: 100% !important;
            font-size: var(--bs-body-font-size);
            font-weight: 400;
            line-height: 1.625;

            &::placeholder {
                color: var(--ecume-125) !important;
                padding: 0;
            }

            &:focus {
                &::placeholder {
                    padding: 0;
                    font-size: calc(1.285rem + .42vw);
                    color: var(--ecume-125) !important;
                }
            }
        }

        > ul {
            z-index: 500;
        }

        > ul > li {
            font-size: var(--bs-body-font-size);
            color: var(--ecume-125) !important;

            mark {
                color: $ecume-360;
            }
        }
    }

    &.form-usuall {
        form {
            .form-item {
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                }
            }
        }
    }
}

.datepicker-input {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: 98% center;
    flex-shrink: 0;
}

.form-usuall {
    &-wrapper {
        span.required {
            color: #df2100;
            font-size: 1.6rem;
            top: -.1rem;
        }
    }

    &-confirmation {
        .material-symbols-outlined {
            float: left;
            color: var(--emeraude-850);
            margin-right: .8rem;
        }
    }

    label {
        line-height: 2.6rem;
    }

    input:not([type='checkbox']):not([type='radio']) {
        height: 4.2rem;
        padding: .8rem;
        border-color: $gray-400;

        @include placeholder {
            color: $gray-700;
        }
    }

    .form-select {
        height: 4.2rem;

        &:has(option[value='']:checked) {
            color: $gray-700;
        }
    }

    .js-form-select-multiple.select2-hidden-accessible + .select2-container::before {
        background-position: 98% center;
    }

    textarea {
        height: 17.4rem;
        padding: .8rem;
        border-color: $gray-400;

        @include placeholder {
            color: $gray-700;
        }
    }
    .char-count {
        float: right;
        padding: .1rem 0 0;
        font-size: 1.1rem;
    }
    .char-counter {
        color: var(--ecume-360) !important;
    }
    .form-error {
        color: var(--error);
    }

    .columns {
        @include media-breakpoint-up(md) {
            display: flex;

            > * {
                &:first-child {
                    width: 20.8rem;
                }

                &:last-child {
                    width: calc(100% - 20.8rem);
                    padding-left: 1.6rem;
                }
            }

            &.equal {
                > * {
                    &:first-child {
                        width: 50%;
                        padding-right: .8rem;
                    }

                    &:last-child {
                        width: 50%;
                        padding-left: .8rem;
                    }
                }
            }
        }
    }

    .form-check-input {
        border-color: var(--ecume-125);

        .block-bg-dark & {
            border-color: var(--bs-white);
        }
    }

    .form-check {
        padding-left: 3.2rem;


        .form-check-input {
            margin-left: -3.2rem;
            width: 2.4rem;
            height: 2.4rem;
            margin-top: 0;

            &-small {
                width: 1.8rem;
                height: 1.8rem;
                border-width: .2rem;
                margin-top: .5rem;
            }
        }
    }

    .form-radio-input {
        label {
            position: relative;
            padding-left: 3rem;
            margin-left: 2.4rem;
            cursor: pointer;

            &::before {
                width: 2.2rem;
                height: 2.2rem;
                border-radius: 50%;
                border: .1rem solid var(--ecume-125);
                content: '';
                top: 0;
                position: absolute;
                left: 0;
                display: block;
                bottom: 0;
                margin: auto;
            }

            &::after {
                left: .1rem;
                top: 0;
                width: 2rem;
                height: 2rem;
                border: .2rem solid var(--bs-white);
                content: '';
                position: absolute;
                display: block;
                background: var(--ecume-125);
                border-radius: 50%;
                opacity: 0;
                bottom: 0;
                margin: auto;
            }
        }

        input {
            width: 1px !important;
            height: 1px !important;
            padding: 0 !important;
            margin: -1px !important;
            overflow: hidden !important;
            clip: rect(0, 0, 0, 0) !important;
            white-space: nowrap !important;
            border: 0 !important;

            &:first-child {
                margin-left: 0;

                + label {
                    margin-left: 0;
                }
            }

            &:checked {
                + label {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .privacy {
        margin-top: -2.4rem !important;
    }

    &.block-bg-dark {
        .form-check-input {
            border-color: var(--bs-white);
            border-width: .2rem;
            background-color: transparent;
        }
    }

    .country {
        background-color: var(--bs-secondary-bg);
        pointer-events: none;
    }
}

.form-alert {
    .form-check {
        padding-left: 4rem;

        .form-check-input {
            margin-left: -2.4rem;
        }
    }
}


.autocomplete {
    position: relative;
    display: inline-block;

    &-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;

        div {
            padding: 1rem;
            cursor: pointer;
            background-color: $white;
            border-bottom: .1rem solid $gray-300;

            &:hover {
                background-color: $gray-300;
            }
        }
    }

    &-active {
        background-color: DodgerBlue !important;
        color: $white;
    }
}

.form-notabene {
    form {
        .form-item {
            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }
        }

        .form-buttons {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 25%;
            }
        }
    }
}

.form-publications {
    form {
        .form-item {
            padding-right: 0;

            @include media-breakpoint-down(lg) {
                margin-top: 0;
            }
        }
        .form-buttons {
            width: 100%;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 1.6rem!important;
            }
        }
    }
}

.fake-search {
    &-wrapper {
        @include media-breakpoint-down(lg) {
            margin-left: -1.6rem;
            margin-right: -1.6rem;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
    }

    .form-control {
        height: 4.2rem;
        padding-right: 4rem;
    }

    .btn-submit {
        color: var(--ecume-125);
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 1.25rem 1rem;
        border-radius: .2rem;

        > * {
            color: var(--ecume-125);
        }
    }
}

.clinical-trial {
    &-filters {
        @include media-breakpoint-down(lg) {
            display: none;
            position: fixed;
            background: white;
            top: 0;
            z-index: 12345;
            left: 0;
            height: 100vh;

            &.show {
                display: block;
                overflow: auto;
            }
        }

        .form-buttons {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            > * {
                width: auto;
                align-self: center;
            }
        }

        .clinical-trial-toggle {
            border: 0;
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 2.4rem;
            transform: translateY(-50%) ;

            &:focus {
                outline: 2px solid Highlight;
            }

            span {
                pointer-events: none;
            }
        }
    }

    &-collapse {
        padding-bottom: 2.4rem;
        border-bottom: .2rem solid var(--ecume-125);
        position: relative;

        .select2-container {
            width: 100% !important;
        }

        &--btn {
            font-size: 2.4rem;
            font-weight: 800;
            position: relative;
            width: 100%;
            border: none;
            display: block;
            text-align: left;
            line-height: 1.25;
            padding: .2rem 4rem .2rem .2rem;

            @include media-breakpoint-down(md) {
                font-size: 1.8rem;
            }

            &::before, &::after {
                content: '';
                position: absolute;
                right: 1.2rem;
                top: 50%;
                width: 1.5rem;
                height: .2rem;
                background-color: var(--ecume-125);
                transition: transform .3s ease;
            }

            &::before {
                transform: translateY(-50%) rotate(90deg);
            }

            &::after {
                transform: translateY(-50%);
            }

            &[aria-expanded='true'] {
                &::before {
                    transform: translateY(-50%) rotate(0);
                }
            }
        }

        .form-item {
            margin-top: 1.6rem;
        }

        input:not([type='checkbox']):not([type='radio']) {
            height: 4.2rem;
            padding: .8rem;
            border-color: $gray-400;

            @include placeholder {
                color: $gray-700;
            }
        }

        label {
            display: block;
        }

        .select2-container--disabled {
            pointer-events: none;
            .selected-text {
                background-color: var(--bs-secondary-bg);
                pointer-events: none;
            }
        }

    }
}

.form-acts {
    form {
        width: 100%;

        .form-control-long {
            @include media-breakpoint-down(lg) {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.form-filters-absolute {
    position: absolute;
    right: 0;
    z-index: 1;
    bottom: -8.2rem;
    @include media-breakpoint-down(xl) {
        bottom: -9rem;
    }

    @include media-breakpoint-down(lg) {
        right: 1.6rem;
    }

    .form-item {
        display: flex;

        select {
            padding-right: 3rem;
            max-width: 20.8rem!important;
        }

        label {
            color: var(--ecume-125);
            flex: 1;
            line-height: 4.2rem;
        }
    }
}

.form-sorting {
    .form-item {
        display: flex;
        min-width: 30rem;

        @include media-breakpoint-down(lg) {
            justify-content: flex-start;
        }

        select {
            padding-right: 3rem;
            max-width: 20.8rem!important;
            width: 100%;
        }

        label {
            color: var(--ecume-125);
            flex: 1;
            line-height: 4.2rem;
            flex-shrink: 0;
            margin-right: 2.4rem;
            max-width: 6.7rem;
        }
    }
}

.toggle-password {
    position: absolute;
    right: .8rem;
    bottom: .9rem;
}

.password-validation {
    &-item {
        position: relative;
        margin-bottom: .4rem;

        .material-symbols-outlined {
            display: none;
            position: absolute;
            width: 2.4rem;
            left: -3rem;
        }

        &.is-valid {
            font-weight: 800;

            .material-symbols-outlined {
                display: block;
            }
        }
    }
}

.form-compact {
    span.required {
        color: #df2100;
        font-size: 1.6rem;
        top: -.1rem;
    }

    .form-check {
        .form-check-input {
            border-width: .2rem;
            border-color: var(--ecume-125);

            &[type=checkbox] {
                width: 1.8rem;
                height: 1.8rem;
                margin-top: .5rem;
            }

            &[type=radio] {
                width: 2rem;
                height: 2rem;
                margin-top: .2rem;
            }
        }
    }

    .outside-item {
        position: relative;

        .form-check {
            padding-left: 0;

            &-input {
                position: absolute;
                left: -4.7rem;

                @include media-breakpoint-down(md) {
                    left: -3rem;
                }
            }
        }
    }

    .form-end {
        .form-check {
            padding-left: 3.2rem;

            &-input {
                margin-left: -3.2rem;
            }
        }
    }

    fieldset {
        .form-check {
            margin-bottom: .8rem;
        }
    }

    legend {
        margin-bottom: 1.2rem;
    }
}
