/**
Font family
*/
/**
Colors
*/
/**
colors
*/
.article-section {
  margin: 0 auto 13.6rem;
  max-width: 76.8rem;
}
@media (max-width: 767.98px) {
  .article-section {
    margin: 0 auto 6.4rem;
  }
}
.article-section.small-section {
  max-width: 65.6rem;
}
@media (max-width: 767.98px) {
  .article-section > h2, .article-section > .like-h2 {
    padding-right: 2.5rem;
  }
}
.article-section-date {
  font-size: 1.4rem;
  margin: 3.2rem 0;
}
@media (max-width: 767.98px) {
  .article-section-date {
    margin: 0.8rem 0 1.6rem;
  }
}
.article-section-chapo {
  font-size: 1.8rem;
  line-height: 1.444;
  margin: 1.6rem 0 4.8rem;
}
@media (max-width: 767.98px) {
  .article-section-chapo {
    margin: 1.6rem 0 3.2rem;
  }
}
.article-section .tags-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  line-height: normal;
  margin: 0;
}
.article-section .tags-group .tag {
  border-radius: 0.4rem 0.4rem 0.8rem 0;
  border: 0.1rem solid var(--ecume-125);
  font-size: 1.4rem;
  padding: 0.8rem;
  margin: 0;
}
.article-section .fr-content-media, .article-section > .enhancedimage--wrapper {
  margin: 1.6rem 0 4rem;
}
@media (max-width: 767.98px) {
  .article-section .fr-content-media, .article-section > .enhancedimage--wrapper {
    margin: 1.6rem -1.6rem 3.2rem;
  }
}
.article-section .fr-content-media img, .article-section > .enhancedimage--wrapper img {
  border-radius: 0.4rem 0.4rem 2.4rem;
}
@media (max-width: 767.98px) {
  .article-section .fr-content-media img, .article-section > .enhancedimage--wrapper img {
    border-radius: 2.4rem 0.4rem;
  }
}
@media (max-width: 767.98px) {
  .article-section .fr-content-media .fr-content-media__caption, .article-section > .enhancedimage--wrapper .fr-content-media__caption {
    padding: 0.8rem 1.6rem 0;
  }
}
.article-section .date-project > span {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.444;
}
@media (max-width: 767.98px) {
  .article-section .date-project > span {
    line-height: 1.333;
  }
}
@media (max-width: 767.98px) {
  .article-section .date-project time {
    font-size: 1.4rem;
  }
}

.card-inca {
  --bs-card-border-color: var(--ecume-360);
  --bs-border-radius: .4rem .4rem 2.4rem .4rem;
  --bs-card-border-width: 0;
  border-top-width: 1rem;
}
.card-inca:hover .material-symbols-outlined:last-child.ibg, .card-inca:focus .material-symbols-outlined:last-child.ibg {
  background: var(--emeraude-900);
}
.card-inca:hover .material-symbols-outlined:last-child.text-primary, .card-inca:focus .material-symbols-outlined:last-child.text-primary {
  color: var(--ecume-125--hover) !important;
}
.card-inca-w-border {
  border-bottom-width: 0.2rem;
  border-left-width: 0.2rem;
  border-right-width: 0.2rem;
}
.card-inca-w-border .card-body {
  padding: 3rem 2.4rem 3.8rem;
}
.card-inca-w-border .card-body .material-symbols-outlined:last-child {
  bottom: 3.8rem;
  position: absolute;
  right: 2.4rem;
}
.card-inca-w-border .card-title {
  padding-right: 3.2rem;
}
.card-inca-no-border {
  border-top: none;
  position: relative;
}
.card-inca-no-border::after {
  right: 0;
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  display: block;
  background: var(--ecume-360);
  height: 0.2rem;
}
.card-inca-no-border--bottom::after {
  top: initial;
  bottom: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal {
    flex-direction: row;
  }
}
.card-inca-horizontal .card-body {
  align-self: center;
  max-width: 100%;
  order: 2;
  padding: 2.4rem 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body {
    max-width: none;
    padding: 2.4rem 1.6rem 6.2rem;
  }
  .list-articles-item .card-inca-horizontal .card-body {
    padding-bottom: 1.6rem;
  }
}
.push .card-inca-horizontal .card-body {
  align-self: flex-start;
}
.list-articles-item .card-inca-horizontal .card-body {
  max-width: 100%;
}
.card-inca-horizontal .card-body .card-title {
  font-size: 2.4rem;
  margin: 0 0 1.6rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-title {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-title {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end {
  max-width: 73.7rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-end {
    font-size: 1.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-body .card-end {
    font-size: 1.6rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-end {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end p {
  margin: 0;
}
.card-inca-horizontal .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  bottom: 2.4rem;
  right: 2.4rem;
}
.card-inca-horizontal .card-header {
  background: none;
  order: 1;
  padding: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header {
    flex: 0 0 32.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-header {
    flex: 0 0 43.2rem;
  }
}
.block-bg-dark .card-inca-horizontal .card-header {
  padding: 0 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .block-bg-dark .card-inca-horizontal .card-header {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header picture {
    height: 100%;
  }
}
.card-inca-horizontal .card-header img {
  aspect-ratio: 1.5;
  border-radius: 0 0 2.4rem;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
.block-bg-dark .card-inca-horizontal .card-header img {
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub .card-header img {
  aspect-ratio: auto;
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub::after {
  z-index: 1;
}
.card-inca-light {
  border-width: 0.2rem;
  min-height: 9.2rem;
  padding: 1.6rem;
}
.card-inca-light:hover {
  background: var(--ecume-975);
}
.card-inca-light-wrapper .row {
  --bs-gutter-x: 1.8rem;
  gap: 1.8rem 0;
  margin: 0;
}
.row .card-inca-light-wrapper .row {
  margin: 0 -0.9rem;
}
.card-inca-light.card-inca .card-body {
  flex-direction: revert;
  gap: 0 0.8rem;
  justify-content: space-between;
  padding: 0;
}
.card-inca-light.card-inca .card-body .card-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
}
.card-inca-light.card-inca .card-body .card-title a {
  text-decoration: none;
}
.card-inca-light.card-inca .card-body .material-symbols-outlined:last-child {
  align-self: flex-start;
  margin: 0;
}
.card-inca-contact {
  margin: 6.4rem auto 0;
  max-width: 65.6rem;
}
.card-inca-contact .card-body {
  padding: 1.4rem 2.2rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body {
    padding: 1.4rem 1.4rem 2.2rem;
  }
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row {
    --bs-gutter-x: 4rem;
  }
}
.card-inca-contact .card-body .row:not(:first-child) {
  margin-top: 1.6rem;
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row [class*=col-] {
    max-width: 29.3rem;
  }
}
.card-inca-contact .card-body .card-title {
  font-size: 2.4rem;
  order: initial;
  margin: 0 0 1.5rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .card-title {
    font-size: 1.8rem;
  }
}
.card-inca-contact .card-body .title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.444;
  margin: 0;
}
.card-inca-contact .card-body .function {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .function {
    font-size: 1.4rem;
    margin: 0 0 1.6rem;
  }
}
.card-inca-contact .card-body .list-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem 0;
}
.card-inca-contact .card-body .list-content li {
  font-size: 1.6rem;
  line-height: normal;
  position: relative;
  padding-left: 2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li {
    font-size: 1.4rem;
  }
}
.card-inca-contact .card-body .list-content li .incaicon {
  font-size: 1.8rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li .incaicon {
    margin-top: 0.2rem;
  }
}
.card-inca-key-figure {
  --bs-card-border-color: var(--bs-white);
  --bs-card-bg: var(--ecume-360);
  background: var(--bs-ecume-360) !important;
}
.card-inca-key-figure-wrapper {
  border-radius: 2.4rem 0.4rem;
  background: var(--ecume-360);
  padding: 2.4rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure-wrapper {
    padding: 1.6rem;
  }
}
.card-inca-key-figure .card-body {
  padding: 3rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-body {
    padding: 2.2rem 2.2rem 3rem;
  }
}
.card-inca-key-figure .card-title, .card-inca-key-figure .card-start {
  color: var(--bs-white);
}
.card-inca-key-figure .card-title {
  display: flex;
  flex-direction: column;
}
.card-inca-key-figure .card-title .num {
  font-size: 6.4rem;
  letter-spacing: 0.192rem;
  line-height: 1.25;
}
.card-inca-key-figure .card-title .text {
  font-size: 3.2rem;
  line-height: normal;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-title .text {
    font-size: 2.4rem;
    line-height: 1.333;
  }
}
.card-inca-key-figure .card-start p {
  line-height: 1.286;
  margin: 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-start p {
    font-size: 1.2rem;
    margin: 0 0 0.8rem;
  }
}
.card-inca .card-img-overlay {
  --bs-card-img-overlay-padding: 2.4rem;
  align-items: flex-end;
  background-image: linear-gradient(180deg, rgba(var(--ecume-125-rgb), 0.05) 16.47%, rgba(var(--ecume-125-rgb), 0.8) 62.21%);
  border-radius: 0 0 2.4rem 0.4rem;
  display: flex;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay {
    --bs-card-img-overlay-padding: 1.6rem 1.4rem 2.2rem 1.4rem;
  }
}
.card-inca .card-img-overlay + picture {
  border-radius: 0 0 2.4rem 0.4rem;
  height: 100%;
  overflow: hidden;
}
.card-inca .card-img-overlay + picture img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.card-inca .card-img-overlay .card-body, .card-inca .card-img-overlay .card-body * {
  color: var(--bs-white);
}
.card-inca .card-img-overlay .card-body {
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-body .material-symbols-outlined:last-child {
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title {
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title a {
    display: block;
    line-height: 1.1875;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-text {
    font-size: 1.4rem;
    line-height: 1.57;
    margin-top: 0.8rem;
  }
}
.card-inca .card-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.card-inca .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  align-self: flex-end;
  color: var(--ecume-125);
  margin-top: 1.6rem;
  order: 4;
  width: 2.4rem;
}
.card-inca .card-body .btn {
  margin: 1.6rem 0 0 auto;
  order: 4;
}
.block-bg-dark .card-inca .card-body .btn {
  color: var(--ecume-125);
}
.block-bg-dark .card-inca .card-body .btn:hover, .block-bg-dark .card-inca .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--ecume-125--hover);
  color: var(--ecume-125--hover);
}
.card-inca .card-title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: normal;
  margin: 1.6rem 0 0;
  order: 2;
}
.card-inca .card-title a {
  text-decoration: none;
}
.card-inca .card-text {
  margin: 1.6rem 0 0;
  order: 3;
}
.card-inca .card-start {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  order: 1;
  width: 100%;
}
@media (max-width: 767.98px) {
  .card-inca .card-start {
    font-size: 1.2rem;
  }
}
.card-inca .card-start .tags-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  line-height: normal;
  margin: 0 0 1.6rem;
}
.card-inca .card-start .tags-group .tag {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-start .tags-group .tag {
    font-size: 1.2rem;
  }
}
.card-inca .card-end {
  order: 4;
}
.card-inca .card-header.full-img picture {
  width: 100%;
}
.card-inca .card-header.full-img img {
  width: 100%;
  object-fit: cover;
  max-height: 27.6rem;
}
.card-inca .card-text-icon .incaicon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  font-size: 2rem;
}
.card-inca .card-type-badge {
  border-radius: 0.4rem 0.4rem 0.8rem 0;
  background: var(--ecume-125);
  color: var(--bs-white);
  padding: 0.8rem;
}
.card-inca-hover {
  border-radius: 0.4rem;
}
.card-inca-hover:focus-within, .card-inca-hover:hover, .card-inca-hover.active {
  background: var(--ecume-975);
}
.card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  float: right;
}
@media (min-width: 992px) {
  .card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
  }
}
.popover .card-inca {
  font-size: 1.4rem;
  min-height: 0 !important;
}
.popover .card-inca p {
  margin-bottom: 0 !important;
}
.card-inca .text.site {
  width: calc(100% - 3.3rem);
}
.card-inca-source {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled {
    width: 80%;
  }
}
.card-inca-trial .list-unstyled li {
  width: 100%;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled li {
    width: 50%;
    padding-right: 2rem;
  }
}
.card-inca-pub {
  min-height: 34.3rem;
}
.card-inca-pub .card-header picture {
  margin: 0 auto;
}
.card-inca-pub .card-header img {
  border-radius: 0;
  width: auto;
  min-width: 0;
  min-height: 0;
}
.card-inca-pub .card-body {
  padding: 2.4rem;
  align-self: flex-start;
}
@media (min-width: 992px) {
  .card-inca-pub .card-pub-links {
    text-align: right;
  }
}
.card-inca-pub .card-pub-links a {
  text-decoration: none;
}
.card-notice .card-title {
  order: unset;
}
.card-top-block {
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 767.98px) {
  .card-top-block {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
.card-top-subtitle {
  margin-right: -4.8rem !important;
  text-align: right;
}
@media (max-width: 767.98px) {
  .card-top-subtitle {
    margin-right: 0 !important;
    text-align: left;
  }
}

.card-fake-btn-absolute {
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .card-fake-btn-absolute {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
    padding-bottom: 0;
  }
}

.scrollspy {
  position: sticky;
  top: 3rem;
  z-index: 100;
  transition: top 0.4s ease;
}
.scrollspy.active-sticky > h2 {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.scrollspy.active-sticky > h2:not(caption) {
  position: absolute !important;
}
@media (min-width: 768px) {
  .scrollspy.active-sticky .collapse-toggle {
    display: block;
  }
}
@media (min-width: 768px) {
  .scrollspy.active-sticky .collapse-toggle[aria-expanded=true] {
    border-bottom-color: transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .scrollspy.active-sticky .collapse-toggle[aria-expanded=true] .incaicon-angle-right {
    transform: translateY(-50%) rotate(-270deg);
  }
}
@media (min-width: 768px) {
  .scrollspy.active-sticky .collapse, .scrollspy.active-sticky .collapsing {
    position: absolute;
    margin-top: -0.3rem;
    width: 100%;
    padding: 0 1.6rem 0.9rem;
    background-color: #fff;
    border-radius: 0 0 2.4rem 0.4rem;
    border-top: 0;
    border-bottom: 0.2rem;
    border-left: 0.2rem;
    border-right: 0.2rem;
    border-style: solid;
    border-color: #3d539b;
  }
}
@media (min-width: 768px) {
  .scrollspy:not(.active-sticky) {
    padding: 1.5rem 1.6rem 0.9rem;
    border-radius: 0.4rem 0.4rem 2.4rem;
    background-color: #fff;
    border: 0.2rem solid #3d539b;
  }
}
@media (min-width: 768px) {
  .scrollspy:not(.active-sticky) .collapse {
    display: block;
  }
}
.scrollspy .collapse-toggle {
  display: none;
  padding: 1.6rem 8.5rem 1.6rem 1.6rem;
  border-radius: 0.4rem 0.4rem 2.4rem;
  background-color: #fff;
  border: 0.2rem solid #3d539b;
  text-align: left;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .scrollspy .collapse-toggle__title {
    font-size: 1.8rem;
    font-weight: 800;
  }
}
@media (min-width: 768px) {
  .scrollspy .collapse-toggle .incaicon-angle-right {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    width: 5.6rem;
    height: 5.6rem;
    border: 0.2rem solid #17203b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    transition: all 0.4s ease;
  }
}
@media (max-width: 767.98px) {
  .scrollspy .collapse:not(.show) {
    display: block;
  }
}
.scrollspy .collapsing {
  height: auto;
  transition: unset;
  overflow: unset;
}
.scrollspy > h2 {
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
}
@media (max-width: 767.98px) {
  .scrollspy > h2 {
    margin-bottom: 1.1rem;
  }
}
.scrollspy .dropdown-toggle {
  width: 100%;
  background-color: #fff;
  border: 0.1rem solid #ccc;
  border-radius: 0.2rem;
  padding: 0.8rem 3.4rem 0.8rem 0.8rem;
  text-align: left;
  white-space: unset;
  line-height: 1.2;
  position: relative;
}
.scrollspy .dropdown-toggle::after {
  display: none;
}
.scrollspy .dropdown-toggle .incaicon-angle-right {
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  border-radius: 50%;
  font-size: 2.4rem;
  transition: all 0.4s ease;
}
.scrollspy .dropdown-toggle[aria-expanded=true] {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.scrollspy .dropdown-toggle[aria-expanded=true] .incaicon-angle-right {
  transform: translateY(-50%) rotate(-90deg);
}
@media (min-width: 768px) {
  .scrollspy__list {
    position: unset;
    border: 0;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .scrollspy__list {
    padding: 0.8rem;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    margin-top: -0.2rem !important;
    width: 100%;
    border-top: 0;
    border-left: 0.1rem;
    border-right: 0.1rem;
    border-bottom: 0.1rem;
    border-style: solid;
    border-color: #ccc;
  }
}
.scrollspy__list .dropdown-item {
  padding: 0.2rem 0 0.6rem;
  white-space: unset;
}
.scrollspy__list .dropdown-item:hover, .scrollspy__list .dropdown-item:focus {
  background: 0;
  text-decoration: underline;
}
.scrollspy__list .dropdown-item.active, .scrollspy__list .dropdown-item:active {
  background: none;
  color: inherit;
}
.scrollspy-anchor {
  scroll-margin-top: 37rem;
}
@media (max-width: 767.98px) {
  .scrollspy-anchor {
    scroll-margin-top: 20rem;
  }
}

.block-bg-dark .form-control, .block-bg-dark .form-select, .block-bg-dark option {
  color: var(--ecume-125);
}

.form-moteur form {
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
  .form-moteur form {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.8rem;
  }
}
.form-moteur form label {
  font-weight: 400;
  font-size: 1.6rem;
  padding-right: 0.8rem;
  flex: 0;
}
.form-moteur form .label-block label {
  display: block;
}
.form-moteur form .select-100 .select2-container {
  width: 100% !important;
}
.form-moteur form .long-label label {
  flex: 1;
}
.form-moteur form input, .form-moteur form select {
  height: 4.2rem;
}
.form-moteur form select {
  width: 20.8rem;
}
@media (max-width: 991.98px) {
  .form-moteur form select {
    width: 100%;
  }
}
.form-moteur form input {
  max-width: 24rem;
  width: auto;
}
@media (max-width: 767.98px) {
  .form-moteur form input {
    max-width: 13rem;
  }
}
@media (min-width: 992px) {
  .form-moteur form input.form-control-long {
    width: 100%;
    min-width: 43.2rem;
  }
}
.form-moteur form input::-webkit-calendar-picker-indicator {
  pointer-events: none !important;
}
.form-moteur form input[type=date]::-webkit-input-placeholder {
  pointer-events: none !important;
}
.form-moteur form .form-content {
  display: flex;
  align-items: end;
  flex: 0;
}
@media (max-width: 991.98px) {
  .form-moteur form .form-content {
    display: block;
    width: 100%;
  }
  .form-moteur form .form-content .align-items-start {
    flex-direction: column !important;
  }
  .form-moteur form .form-content > .form-item {
    width: 100%;
  }
  .form-moteur form .form-content > .form-item + * {
    margin-top: 2.4rem;
  }
}
.form-moteur form .form-item {
  padding-right: 0;
  position: relative;
}
@media (min-width: 992px) {
  .form-moteur form .form-item {
    padding-right: 1.6rem;
  }
}
@media (max-width: 991.98px) {
  .form-moteur form .form-item {
    margin-top: 3rem;
    width: 100%;
  }
}
.form-moteur form .form-item .btn-link {
  color: #fff;
}
.form-moteur form .form-item .btn-link:hover, .form-moteur form .form-item .btn-link:focus {
  color: #fff;
}
.form-moteur form .form-item .form-select:disabled {
  opacity: 0.4;
}
.form-moteur form fieldset.form-item {
  min-width: initial;
  padding: 0;
}
@media (min-width: 992px) {
  .form-moteur form fieldset.form-item {
    padding-right: 1.6rem;
  }
}
.form-moteur form .date-range {
  justify-content: space-between;
}
.form-moteur form .date-range .form-item {
  display: flex;
  align-items: center;
  width: calc(50% - 0.8rem);
  padding-right: 0;
}
.form-moteur form .date-range .form-item:first-child {
  margin-right: 1.6rem;
}
.form-moteur .form-buttons {
  display: flex;
  align-items: end;
  gap: 2.4rem;
}
@media (max-width: 991.98px) {
  .form-moteur .form-buttons {
    width: 100%;
    align-items: center;
  }
  .form-moteur .form-buttons .btn {
    width: 50%;
    justify-content: center;
  }
}
.form-moteur .form-buttons .btn-submit .incaicon {
  color: var(--ecume-125);
  font-size: 1.4rem;
  margin-top: 0.2rem;
}
.form-moteur .form-buttons .btn-reset {
  opacity: 0.4;
}
.form-moteur .form-buttons .btn-reset[data-enabled=true] {
  opacity: 1;
}
.form-moteur .form-buttons .btn-reset .incaicon {
  font-size: 1.6rem;
  margin-top: 0.2rem;
  font-weight: 800;
  color: currentColor;
}
.form-moteur .form-buttons .btn-reset:hover {
  opacity: 1;
}
.form-moteur .form-buttons .btn-reset:hover .incaicon {
  color: var(--ecume-125);
}
.form-moteur .form-buttons .btn-reset:active {
  opacity: 1;
  border-color: var(--bs-btn-active-color);
}
.form-moteur .form-buttons .btn-reset:focus {
  opacity: 1;
}
.form-moteur-pro form {
  margin: 0;
}
.form-moteur-pro form .row {
  gap: 2.4rem 0;
}
@media (max-width: 767.98px) {
  .form-moteur-pro form .row .form-item-input .form-control {
    max-width: 100%;
    width: 100%;
  }
}
.form-moteur-pro form .row .date-range .form-item {
  width: 50%;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .form-moteur-pro form .row .form-content-multiple {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
.form-moteur-pro form .row .form-buttons {
  justify-content: center;
  margin: 0 !important;
}
.form-moteur .autoComplete_wrapper {
  display: block;
}
.form-moteur .autoComplete_wrapper input {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  background-image: none !important;
  color: var(--ecume-125) !important;
  padding: 0.375rem 0.75rem;
  border-radius: var(--bs-border-radius) !important;
  max-width: none !important;
  width: 100% !important;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.625;
}
.form-moteur .autoComplete_wrapper input::placeholder {
  color: var(--ecume-125) !important;
  padding: 0;
}
.form-moteur .autoComplete_wrapper input:focus::placeholder {
  padding: 0;
  font-size: calc(1.285rem + 0.42vw);
  color: var(--ecume-125) !important;
}
.form-moteur .autoComplete_wrapper > ul {
  z-index: 500;
}
.form-moteur .autoComplete_wrapper > ul > li {
  font-size: var(--bs-body-font-size);
  color: var(--ecume-125) !important;
}
.form-moteur .autoComplete_wrapper > ul > li mark {
  color: #3d539b;
}
@media (max-width: 767.98px) {
  .form-moteur.form-usuall form .form-item {
    margin-top: 0;
  }
}

.datepicker-input {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: 98% center;
  flex-shrink: 0;
}

.form-usuall-wrapper span.required {
  color: #df2100;
  font-size: 1.6rem;
  top: -0.1rem;
}
.form-usuall-confirmation .material-symbols-outlined {
  float: left;
  color: var(--emeraude-850);
  margin-right: 0.8rem;
}
.form-usuall label {
  line-height: 2.6rem;
}
.form-usuall input:not([type=checkbox]):not([type=radio]) {
  height: 4.2rem;
  padding: 0.8rem;
  border-color: #e5e5e5;
}
.form-usuall input:not([type=checkbox]):not([type=radio])::placeholder {
  color: #666;
}
.form-usuall .form-select {
  height: 4.2rem;
}
.form-usuall .form-select:has(option[value=""]:checked) {
  color: #666;
}
.form-usuall .js-form-select-multiple.select2-hidden-accessible + .select2-container::before {
  background-position: 98% center;
}
.form-usuall textarea {
  height: 17.4rem;
  padding: 0.8rem;
  border-color: #e5e5e5;
}
.form-usuall textarea::placeholder {
  color: #666;
}
.form-usuall .char-count {
  float: right;
  padding: 0.1rem 0 0;
  font-size: 1.1rem;
}
.form-usuall .char-counter {
  color: var(--ecume-360) !important;
}
.form-usuall .form-error {
  color: var(--error);
}
@media (min-width: 768px) {
  .form-usuall .columns {
    display: flex;
  }
  .form-usuall .columns > *:first-child {
    width: 20.8rem;
  }
  .form-usuall .columns > *:last-child {
    width: calc(100% - 20.8rem);
    padding-left: 1.6rem;
  }
  .form-usuall .columns.equal > *:first-child {
    width: 50%;
    padding-right: 0.8rem;
  }
  .form-usuall .columns.equal > *:last-child {
    width: 50%;
    padding-left: 0.8rem;
  }
}
.form-usuall .form-check-input {
  border-color: var(--ecume-125);
}
.block-bg-dark .form-usuall .form-check-input {
  border-color: var(--bs-white);
}
.form-usuall .form-check {
  padding-left: 3.2rem;
}
.form-usuall .form-check .form-check-input {
  margin-left: -3.2rem;
  width: 2.4rem;
  height: 2.4rem;
  margin-top: 0;
}
.form-usuall .form-check .form-check-input-small {
  width: 1.8rem;
  height: 1.8rem;
  border-width: 0.2rem;
  margin-top: 0.5rem;
}
.form-usuall .form-radio-input label {
  position: relative;
  padding-left: 3rem;
  margin-left: 2.4rem;
  cursor: pointer;
}
.form-usuall .form-radio-input label::before {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  border: 0.1rem solid var(--ecume-125);
  content: "";
  top: 0;
  position: absolute;
  left: 0;
  display: block;
  bottom: 0;
  margin: auto;
}
.form-usuall .form-radio-input label::after {
  left: 0.1rem;
  top: 0;
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid var(--bs-white);
  content: "";
  position: absolute;
  display: block;
  background: var(--ecume-125);
  border-radius: 50%;
  opacity: 0;
  bottom: 0;
  margin: auto;
}
.form-usuall .form-radio-input input {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.form-usuall .form-radio-input input:first-child {
  margin-left: 0;
}
.form-usuall .form-radio-input input:first-child + label {
  margin-left: 0;
}
.form-usuall .form-radio-input input:checked + label::after {
  opacity: 1;
}
.form-usuall .privacy {
  margin-top: -2.4rem !important;
}
.form-usuall.block-bg-dark .form-check-input {
  border-color: var(--bs-white);
  border-width: 0.2rem;
  background-color: transparent;
}
.form-usuall .country {
  background-color: var(--bs-secondary-bg);
  pointer-events: none;
}

.form-alert .form-check {
  padding-left: 4rem;
}
.form-alert .form-check .form-check-input {
  margin-left: -2.4rem;
}

.autocomplete {
  position: relative;
  display: inline-block;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 1rem;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 0.1rem solid #ebebeb;
}
.autocomplete-items div:hover {
  background-color: #ebebeb;
}
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #fff;
}

@media (max-width: 991.98px) {
  .form-notabene form .form-item {
    margin-top: 0;
  }
}
.form-notabene form .form-buttons {
  width: 100%;
}
@media (min-width: 992px) {
  .form-notabene form .form-buttons {
    width: 25%;
  }
}

.form-publications form .form-item {
  padding-right: 0;
}
@media (max-width: 991.98px) {
  .form-publications form .form-item {
    margin-top: 0;
  }
}
.form-publications form .form-buttons {
  width: 100%;
}
@media (min-width: 992px) {
  .form-publications form .form-buttons {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .form-publications form .form-buttons {
    margin-top: 1.6rem !important;
  }
}

@media (max-width: 991.98px) {
  .fake-search-wrapper {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
.fake-search .form-control {
  height: 4.2rem;
  padding-right: 4rem;
}
.fake-search .btn-submit {
  color: var(--ecume-125);
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1.25rem 1rem;
  border-radius: 0.2rem;
}
.fake-search .btn-submit > * {
  color: var(--ecume-125);
}

@media (max-width: 991.98px) {
  .clinical-trial-filters {
    display: none;
    position: fixed;
    background: white;
    top: 0;
    z-index: 12345;
    left: 0;
    height: 100vh;
  }
  .clinical-trial-filters.show {
    display: block;
    overflow: auto;
  }
}
.clinical-trial-filters .form-buttons {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.clinical-trial-filters .form-buttons > * {
  width: auto;
  align-self: center;
}
.clinical-trial-filters .clinical-trial-toggle {
  border: 0;
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 2.4rem;
  transform: translateY(-50%);
}
.clinical-trial-filters .clinical-trial-toggle:focus {
  outline: 2px solid Highlight;
}
.clinical-trial-filters .clinical-trial-toggle span {
  pointer-events: none;
}
.clinical-trial-collapse {
  padding-bottom: 2.4rem;
  border-bottom: 0.2rem solid var(--ecume-125);
  position: relative;
}
.clinical-trial-collapse .select2-container {
  width: 100% !important;
}
.clinical-trial-collapse--btn {
  font-size: 2.4rem;
  font-weight: 800;
  position: relative;
  width: 100%;
  border: none;
  display: block;
  text-align: left;
  line-height: 1.25;
  padding: 0.2rem 4rem 0.2rem 0.2rem;
}
@media (max-width: 767.98px) {
  .clinical-trial-collapse--btn {
    font-size: 1.8rem;
  }
}
.clinical-trial-collapse--btn::before, .clinical-trial-collapse--btn::after {
  content: "";
  position: absolute;
  right: 1.2rem;
  top: 50%;
  width: 1.5rem;
  height: 0.2rem;
  background-color: var(--ecume-125);
  transition: transform 0.3s ease;
}
.clinical-trial-collapse--btn::before {
  transform: translateY(-50%) rotate(90deg);
}
.clinical-trial-collapse--btn::after {
  transform: translateY(-50%);
}
.clinical-trial-collapse--btn[aria-expanded=true]::before {
  transform: translateY(-50%) rotate(0);
}
.clinical-trial-collapse .form-item {
  margin-top: 1.6rem;
}
.clinical-trial-collapse input:not([type=checkbox]):not([type=radio]) {
  height: 4.2rem;
  padding: 0.8rem;
  border-color: #e5e5e5;
}
.clinical-trial-collapse input:not([type=checkbox]):not([type=radio])::placeholder {
  color: #666;
}
.clinical-trial-collapse label {
  display: block;
}
.clinical-trial-collapse .select2-container--disabled {
  pointer-events: none;
}
.clinical-trial-collapse .select2-container--disabled .selected-text {
  background-color: var(--bs-secondary-bg);
  pointer-events: none;
}

.form-acts form {
  width: 100%;
}
@media (max-width: 991.98px) {
  .form-acts form .form-control-long {
    max-width: 100%;
    width: 100%;
  }
}

.form-filters-absolute {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: -8.2rem;
}
@media (max-width: 1199.98px) {
  .form-filters-absolute {
    bottom: -9rem;
  }
}
@media (max-width: 991.98px) {
  .form-filters-absolute {
    right: 1.6rem;
  }
}
.form-filters-absolute .form-item {
  display: flex;
}
.form-filters-absolute .form-item select {
  padding-right: 3rem;
  max-width: 20.8rem !important;
}
.form-filters-absolute .form-item label {
  color: var(--ecume-125);
  flex: 1;
  line-height: 4.2rem;
}

.form-sorting .form-item {
  display: flex;
  min-width: 30rem;
}
@media (max-width: 991.98px) {
  .form-sorting .form-item {
    justify-content: flex-start;
  }
}
.form-sorting .form-item select {
  padding-right: 3rem;
  max-width: 20.8rem !important;
  width: 100%;
}
.form-sorting .form-item label {
  color: var(--ecume-125);
  flex: 1;
  line-height: 4.2rem;
  flex-shrink: 0;
  margin-right: 2.4rem;
  max-width: 6.7rem;
}

.toggle-password {
  position: absolute;
  right: 0.8rem;
  bottom: 0.9rem;
}

.password-validation-item {
  position: relative;
  margin-bottom: 0.4rem;
}
.password-validation-item .material-symbols-outlined {
  display: none;
  position: absolute;
  width: 2.4rem;
  left: -3rem;
}
.password-validation-item.is-valid {
  font-weight: 800;
}
.password-validation-item.is-valid .material-symbols-outlined {
  display: block;
}

.form-compact span.required {
  color: #df2100;
  font-size: 1.6rem;
  top: -0.1rem;
}
.form-compact .form-check .form-check-input {
  border-width: 0.2rem;
  border-color: var(--ecume-125);
}
.form-compact .form-check .form-check-input[type=checkbox] {
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 0.5rem;
}
.form-compact .form-check .form-check-input[type=radio] {
  width: 2rem;
  height: 2rem;
  margin-top: 0.2rem;
}
.form-compact .outside-item {
  position: relative;
}
.form-compact .outside-item .form-check {
  padding-left: 0;
}
.form-compact .outside-item .form-check-input {
  position: absolute;
  left: -4.7rem;
}
@media (max-width: 767.98px) {
  .form-compact .outside-item .form-check-input {
    left: -3rem;
  }
}
.form-compact .form-end .form-check {
  padding-left: 3.2rem;
}
.form-compact .form-end .form-check-input {
  margin-left: -3.2rem;
}
.form-compact fieldset .form-check {
  margin-bottom: 0.8rem;
}
.form-compact legend {
  margin-bottom: 1.2rem;
}

.datepicker {
  width: min-content;
}
.datepicker:not(.active) {
  display: none;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-controls {
  display: flex;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid gainsboro;
  border-radius: 4px;
  box-shadow: none;
  background-color: hsl(0, 0%, 100%);
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: hsl(0, 0%, 21%);
  font-size: 1rem;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #b8b8b8;
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button:focus {
  border-color: hsl(217, 71%, 53%);
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.datepicker-controls .button:active {
  border-color: #474747;
  color: hsl(0, 0%, 21%);
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-footer .datepicker-controls .button {
  flex: auto;
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 2px;
  font-size: 0.75rem;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  flex: 0 0 14.2857142857%;
}
.datepicker-controls .prev-button.disabled,
.datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view,
.datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.datepicker .days-of-week {
  display: flex;
}
.datepicker .week-numbers {
  display: flex;
  flex-direction: column;
  flex: 0 0 9.6774193548%;
}
.datepicker .weeks {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
}
.datepicker span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: default;
  -webkit-touch-callout: none;
  user-select: none;
}
.datepicker .dow {
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}
.datepicker .week {
  flex: auto;
  color: #b8b8b8;
  font-size: 0.75rem;
}

.datepicker-cell, .datepicker .days .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-cell {
  height: 2.25rem;
}
.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e9e9e9;
}
.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: hsl(217, 71%, 53%);
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: gainsboro;
}
.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: hsl(0, 0%, 48%);
}
.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: hsl(0, 0%, 96%);
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #efefef;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9e9e9;
}
.datepicker-cell.today:not(.selected) {
  background-color: hsl(171, 100%, 41%);
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad;
}
.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  background-color: #b8b8b8;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #b3b3b3;
}
.datepicker-cell.range-start:not(.range-end) {
  border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 4px 4px 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: gainsboro;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d7d7d7;
}
.datepicker-cell.range.disabled {
  color: #c6c6c6;
}
.datepicker-cell.range.focused {
  background-color: #d1d1d1;
}

.datepicker-input.in-edit {
  border-color: #276bda;
}
.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(39, 107, 218, 0.2);
}

.datepicker span {
  border-radius: 50%;
}
.datepicker-view {
  width: 24rem;
}
.datepicker-grid {
  padding: 0 0.8rem;
}
.datepicker-cell {
  min-width: 3.2rem;
  height: 3.2rem;
}
.datepicker-cell.focused {
  border: 0.1rem solid var(--ecume-360);
  color: var(--ecume-360);
}
.datepicker-cell.focused:not(.selected) {
  background: var(--bs-white);
}
.datepicker-cell.selected {
  background: var(--ecume-125);
  color: var(--bs-white) !important;
  border-color: var(--ecume-125);
}
.datepicker-cell.selected:hover {
  background: var(--ecume-125);
  color: var(--bs-white);
  border-color: var(--ecume-125);
}
.datepicker .dow {
  font-size: 1.6rem;
  color: var(--ecume-360);
  font-weight: 400;
  text-transform: capitalize;
  height: 3.2rem;
}
.datepicker .days-of-week {
  padding: 0 0.8rem;
}
.datepicker-controls .button {
  font-size: 1.6rem;
  font-weight: 400;
}
.datepicker-controls .button.view-switch {
  order: 1;
  justify-content: flex-start;
  text-transform: capitalize;
  padding-right: 2.4rem;
  background-position: center right;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI0IDI1IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNyAxMC44NTM1TDEyIDE1Ljg1MzVMMTcgMTAuODUzNUg3WiIgZmlsbD0iIzE3MjAzQiIvPgo8L3N2Zz4K");
}
.datepicker-controls .button.prev-button {
  order: 2;
  transform: rotate(180deg);
}
.datepicker-controls .button.next-button {
  order: 3;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #dee2e6;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #dee2e6;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option--group {
  padding: 0;
}
.select2-container--classic .select2-results__option--disabled {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}
.select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-container {
  border: 0.1rem solid #e5e5e5;
}
@media (max-width: 767.98px) {
  .select2-container {
    width: 100% !important;
  }
}
.select2-container .select2-selection--multiple {
  height: 4.2rem;
}
.select2-container--open .select2-dropdown--below {
  border-top: 0.2rem solid var(--ecume-360);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.select2-container--focus, .select2-container:focus-visible {
  outline: 0.1rem auto Highlight;
  outline: -webkit-focus-ring-color auto 0.1rem;
}
.select2-container + .select2-container {
  border: none;
}
.select2-dropdown {
  border-radius: 0.4rem 0.4rem 2.4rem;
  border: 0.2rem solid var(--ecume-360);
}
@media (min-width: 992px) {
  .select2-dropdown {
    min-width: 35rem;
  }
}
.select2-results__options {
  padding: 0.8rem 0;
}
.select2-results__options > * {
  color: var(--ecume-125);
}
.select2-results__option[role=option] {
  padding: 0.8rem 1.6rem 0.8rem 4rem !important;
  position: relative;
  color: var(--ecume-125);
  margin-bottom: 0.1rem;
}
.select2-results__option[role=option]::before {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  left: 0.8rem;
  top: 1.4rem;
  border: 0.2rem solid var(--ecume-125);
  content: "";
  display: block;
}
.select2-results__option[role=option]::after {
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  left: 1rem;
  top: 1.6rem;
  border: 0.2rem solid var(--bs-white);
  background: var(--ecume-125);
  content: "";
  display: none;
}
.select2-results__option[role=option]::first-letter {
  text-transform: uppercase;
}
.select2-results__option--highlighted[role=option] {
  background-color: #5897fb;
  color: hsl(0, 0%, 100%);
  text-shadow: 0.05rem 0 0 currentColor;
  outline: 0.1rem solid var(--ecume-125) !important;
}
.select2-results__option--selected[role=option] {
  background-color: var(--ecume-975) !important;
  color: var(--ecume-125) !important;
}
.select2-results__option--selected[role=option]::after {
  display: block;
}
.select2-results__group {
  color: var(--ecume-125);
}
.select2-container--default .select2-selection--multiple {
  background-color: var(--bs-white);
  border: 0;
  border-radius: 0.2rem;
}
.select2-container--default .select2-search--inline {
  display: none;
}
.select2-container--default .select2-search--inline .select2-search__field {
  margin-top: 0;
}
.select2-selection {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1.6rem 1.2rem;
}
.select2-selection > .select2-selection__rendered {
  display: none !important;
}
.select2 .selected-text {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  color: var(--ecume-125) !important;
  background: hsl(0, 0%, 100%);
  display: block;
  height: 3.9rem;
  padding: 0.8rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 3rem);
}
.select2 .selected-text > span {
  display: inline-block;
}
.select2 .selected-text > span::first-letter {
  text-transform: uppercase;
}
.select2 .selected-text * {
  color: inherit;
  white-space: nowrap;
}
.select2-selection__choice {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.dropdown-select * {
  color: var(--ecume-125);
}
.dropdown-select-btn {
  height: 4.2rem;
  position: relative;
  text-align: left;
  padding-right: 3rem;
}
.dropdown-select-btn::after {
  display: none;
}
.dropdown-select-btn .text {
  display: block;
  overflow: hidden;
}
.dropdown-select-list {
  border-radius: 0.4rem 0.4rem 2.4rem;
}
.dropdown-select-list [role=checkbox] {
  position: relative;
  padding: 0.8rem 1.6rem 0.8rem 4rem;
}
.dropdown-select-list [role=checkbox]::before {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  left: 0.8rem;
  top: 1.4rem;
  border: 0.2rem solid var(--ecume-125);
  content: "";
  display: block;
}
.dropdown-select-list [role=checkbox]::after {
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  left: 1rem;
  top: 1.6rem;
  border: 2px solid var(--bs-white);
  background: var(--ecume-125);
  content: "";
  display: none;
}
.dropdown-select-list [role=checkbox][aria-checked=true]::after {
  display: block;
}
.dropdown-select-text {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}
.dropdown-select-text:nth-child(odd) {
  background-color: var(--ecume-975);
}
.dropdown-select-text a {
  text-decoration: none;
}

.push-wrapper .row {
  --bs-gutter-x: 3.2rem;
  gap: 3.2rem 0;
}
.push-wrapper .row > [class$="-6"] .landing-page__block, .push-wrapper .row > [class$="-6"] .push, .push-wrapper .row > [class$="-6"] .card, .push-wrapper .row > [class$="-6"] .container {
  height: 100%;
}
.push-wrapper .row > [class$="-6"] .card {
  flex-direction: column;
}
@media (min-width: 768px) {
  .push-wrapper .row > [class$="-6"] .card-title {
    font-size: 1.8rem;
  }
}
.push.block-bg-dark .card-inca-horizontal {
  --bs-card-bg: var(--ecume-125);
  --bs-border-radius: 0;
  border: 0;
  padding: 2.4rem 0;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal {
    padding: 6rem 0;
  }
}
.push.block-bg-dark .card-inca-horizontal * {
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body {
  padding: 0 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body {
    padding: 2.4rem 1.6rem 7.2rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
  bottom: 4.8rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
    bottom: 8.4rem;
    right: 1.6rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn {
  --bs-btn-border-color: var(--bs-white);
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn:hover, .push.block-bg-dark .card-inca-horizontal .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-hover-bg: var(--ecume-200);
  --bs-btn-hover-color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-title {
  color: var(--bs-white);
  font-size: 3.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-title {
    font-size: 2.4rem;
    margin: 0 0 2.4rem;
  }
}
.push .card-inca .card-body-w-btn {
  align-self: flex-start;
  padding: 3.8rem 2.4rem 4.6rem 3rem;
  max-width: calc(100% - 14.5rem);
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    padding: 2.2rem 2.2rem 3rem;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn .card-end {
    font-size: 1.6rem;
  }
}
.push .card-inca .card-body-w-btn .btn {
  align-self: center;
  margin: 2.4rem 0 0;
}
@media (min-width: 768px) {
  .push .card-inca .card-body-w-btn .btn {
    bottom: 4.6rem;
    margin: 0;
    position: absolute;
    right: 3rem;
  }
}

.w848 .push-wrapper {
  margin-top: 0;
}
.w848 .push-wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.w848 .push-wrapper .row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.w848 .push-wrapper .row > * {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row > * {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}